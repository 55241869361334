<template>
    <vx-card :title="`Purchase Receive Conversion `">
        <div class="mb-6 vx-row">
            <vs-button @click="handleBack()" class="mt-2 ml-4" color="danger" icon-pack="feather"
                icon="icon-arrow-left">Back</vs-button>
        </div>
        <div class="flex md:flex-row">
            <div class="w-full pr-6 md:basis-1/2">
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Warehouse Code</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-input :value="this.form.warehouse.code + ' - ' + this.form.warehouse.name" disabled
                            :readonly="true" class="w-full bg-grey-light"></vs-input>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Supplier</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-input :value="this.form.supplier.code + ' - ' + this.form.supplier.name" disabled
                            :readonly="true" class="w-full bg-grey-light"></vs-input>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Purchase Data</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-input :value="this.form.code" disabled :readonly="true" class="w-full bg-grey-light"></vs-input>
                    </div>
                </div>
            </div>
            <div class="w-full md:basis-1/2">
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Shipment Number</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-input disabled v-model="form.shipment_number" class="w-full bg-grey-light" />
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Delivery Note Number</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-input disabled v-model="form.delivery_order_number" class="w-full bg-grey-light" />
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Receive Date</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <datepicker name="date" disabled :inline="false" v-model="form.date" class="bg-grey-light"
                            placeholder="Select Date" :cleared="() => {
                                this.form.date = null
                            }">
                        </datepicker>
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-6 vx-row">
            <div class="w-full">
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th colspan="3"></th>
                            <th colspan="2"> Receive Data </th>
                            <th colspan="2"> Conversion Data </th>
                        </tr>
                        <tr>
                            <th>
                                <div>Supplier</div>
                                <div>SKU Code</div>
                            </th>
                            <th>
                                <div>Internal</div>
                                <div>SKU Code</div>
                            </th>
                            <th>
                                <div>Internal</div>
                                <div>SKU Description</div>
                            </th>
                            <th>HU</th>
                            <th>Qty</th>
                            <th>
                                <div>HU</div>
                            </th>
                            <th>
                                <div>Qty</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in form.purchase_receive_lines" :key="index" style="padding-bottom: 5px">
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="item.supplier_sku_code" :readonly="true" disabled
                                    class="w-full bg-grey-light"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="item.internal_sku_code" :readonly="true" disabled
                                    class="w-full bg-grey-light" />
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="item.item_name" :readonly="true" disabled
                                    class="w-full bg-grey-light"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="form.purchase_receive_lines[index].hu_name" disabled
                                    class="w-full bg-grey-light" />
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="form.purchase_receive_lines[index].quantity" disabled
                                    class="w-full bg-grey-light" />
                            </td>
                            <td colspan="2" class="td vs-table--td" style="padding: 5px">
                                <table width="100%" class="vs-table vs-table--tbody-table">
                                    <tbody>
                                        <tr v-for="(line, idx) in item.hu_line" :key="idx">
                                            <td class="td vs-table--td" style="padding: 5px">
                                                <CustomMSelect
                                                    :value="form.purchase_receive_lines[index].hu_line[idx].item_unit"
                                                    :base-url="`/api/wms/v1/master/item-unit/related/${item.item_unit_id}`"
                                                    label="name" track-by="name"
                                                    @selected="(v) => { setHuItemUnit(v, index, idx); }"></CustomMSelect>
                                            </td>
                                            <td class="td vs-table--td" style="padding: 5px">
                                                <vs-input type="number" min="0"  v-model="line.quantity" class="w-full" />
                                            </td>
                                            <td class="td vs-table--td" style="text-align: center">
                                                <div class="flex vx-input-group">
                                                    <vs-button v-if="item.hu_line.length > 1"
                                                        @click.stop="removeItemsRow(index, idx)" size="small" color="danger"
                                                        icon-pack="feather" icon="icon-trash" style="margin-right: 5px"
                                                        title="Remove Row" />
                                                    <vs-button v-if="idx == item.hu_line.length - 1"
                                                        @click.stop="addItemsRow(index)" size="small" color="success"
                                                        icon-pack="feather" icon="icon-plus" title="Add Row" />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="pt-5 mb-6 vx-row">
            <div class="w-full vx-col sm:w-1/1">
                <div
                    style=" position: absolute; left: 50%; -ms-transform: translate(-50%, -50%); transform: translate(-50%, -50%); ">
                    <vs-button class="mb-2 mr-3" @click="handleSubmit">Save</vs-button>
                    <vs-button class="mb-2 mr-3" @click="handleSaveConfirm">Save and Confirm</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import Date from "@/components/Date.vue";
import CustomMSelect from "@/components/CustomMultiSelect.vue";
import vSelect from "vue-select";
import moment from "moment";
import Datepicker from "vuejs-datepicker";

export default {
    components: {
        Date,
        CustomMSelect,
        vSelect,
        Datepicker,
    },
    mounted() {
        this.id = this.$route.params.id;
        this.mode = this.$route.params.mode;
        if (this.id) {
            this.initData();
        } else {
            this.$vs.loading();
            setTimeout(this.$vs.loading.close, 3200);
        }
    },
    data() {
        return {
            dialog: {
                save: false,
            },
            id: null,
            attachment: null,
            form: {
                id: null,
                purchase_order_id: null,
                warehouse: null,
                supplier: null,
                status: "0",
                dn_date: moment().toDate(),
                purchaseData: null,
                code: null,
                date: null,
                notes: null,
                delivery_order_number: null,
                purchase_date: null,
                shipment_number: null,
                purchase_receive_lines: null,
            },
        }
    },
    methods: {
        addItemsRow(index) {
            const newItem = Object.assign({}, this.form.purchase_receive_lines[index].hu_line[0])
            newItem.quantity = 0;
            newItem.id = 0;
            newItem.hu_name = null;
            newItem.item_unit = null;
            this.form.purchase_receive_lines[index].hu_line.push(newItem);
        },
        removeItemsRow(index, idx) {
            this.form.purchase_receive_lines[index].hu_line.splice(idx, 1)
        },
        handleSaveConfirm() {
            this.form.status = "5";
            this.handleSubmit()
        },
        setHuItemUnit(v, groupLineIndex, huLineIndex) {
            var assigned = false;
            var from = this.form.purchase_receive_lines[groupLineIndex]

            if (from.amount_uom < v.amount_uom) {
                this.$vs.dialog({
                    type: "confirm",
                    color: "danger",
                    title: `Cannot convert ${from.hu_name} to ${v.name}`,
                    text: `Cannot convert ${from.hu_name} to ${v.name}. Please replace to the lowest unit level!`,
                });
            } else {
                this.form.purchase_receive_lines[groupLineIndex].hu_line.forEach((line, i) => {
                    if ((i != huLineIndex) && (line.item_unit && line.item_unit.id == v.id)) {
                        assigned = true;
                        return false
                    }
                });
                if (assigned) {
                    this.$vs.dialog({
                        type: "confirm",
                        color: "danger",
                        title: `${v.name} Already Selected`,
                        text: `${v.name} Already Selected. Please replace with another value!`,
                    });
                }
                this.form.purchase_receive_lines[groupLineIndex].hu_line[huLineIndex].item_unit = v;
            }
        },
        validateConversionLine(prLineArr) {
            var err = null;
            prLineArr.forEach(item => {
                var mapUnitItem = new Map()
                item.hu_line.forEach((x) => {
                    if (item.amount_uom < x.item_unit.amount_uom) {
                        err = `cannot convert ${item.quantity} ${item.hu_name} to ${x.quantity} ${x.item_unit.name}. Please check your input!`
                        return false
                    }
                    if (mapUnitItem.has(x.item_unit.id)) {
                        err = `Found duplicate unit item when converting ${item.quantity} ${item.hu_name} to ${x.quantity} ${x.item_unit.name}. Please change your item unit name!`
                        return false
                    } else {
                        mapUnitItem.set(x.item_unit.id, "checking..");
                    }
                })
                if (err != null) {
                    return false;
                }
            });
            return err;
        },
        handleSubmit() {
            const prLineArr = this.form.purchase_receive_lines;
            var err = this.validateConversionLine(prLineArr)
            if (err) {
                this.$vs.dialog({
                    type: "confirm",
                    color: "danger",
                    title: `Invalid form data`,
                    text: err,
                });
            } else {
                const prLine = [];
                var error = null;
                try {
                    Object.entries(prLineArr).map(([key, value]) => {
                        const item = Object.assign({}, value)
                        const receivedQuantity = Number(value.quantity) * Number(item.amount_uom);
                        var conversionQuantity = 0;
                        item.hu_line.forEach((i) => {
                            var quantity_pcs = Number(i.quantity) * Number(i.item_unit.amount_uom)
                            conversionQuantity += quantity_pcs;
                            i.quantity = Number(i.quantity);
                            i.quantity_pcs = quantity_pcs;
                            i.purchase_receive_id = Number(this.id);
                            i.item_unit_id = i.item_unit.id;
                            prLine.push(i);
                        });
                        if (receivedQuantity > conversionQuantity) {
                            item.key = key
                            error = `error converting ${value.quantity} ${value.hu_name}. \nHas ${receivedQuantity - conversionQuantity} pcs left to convert.`
                        } else if (receivedQuantity < conversionQuantity) {
                            error = `error converting ${value.quantity} ${value.hu_name}. \nConversion quantity pcs cannot exceeded the received quantity (${receivedQuantity - conversionQuantity} pcs).`
                        }
                    });

                } catch (e) {
                    error = `please remove or fill the empty field you created!`
                }
                if (error) {
                    this.$vs.dialog({
                        type: "confirm",
                        color: "danger",
                        title: `Error`,
                        text: error,
                    });
                } else {
                    var payload = Object.assign({}, this.form)
                    payload.purchase_receive_lines = prLine
                    this.$http
                        .post(`${this.$store.state.inbound.purchaseOrder.baseUrlPath}/hu-conversion/${this.id}`, payload)
                        .then(resp => {
                            if (resp.code == 200) {
                                this.$vs.loading.close();
                                this.$vs.dialog({
                                    type: "confirm",
                                    color: "success",
                                    title: `Data Updated`,
                                    text: "Updated successfully",
                                    // accept: this.handleBack,
                                });
                            } else {
                                this.$vs.loading.close();
                                this.$vs.dialog({
                                    type: "confirm",
                                    color: "danger",
                                    title: `Error`,
                                    text: `${resp.code} : ${resp.message}`,
                                });
                            }
                        });
                }
            }

        },
        handleBack() {
            this.$vs.loading();
            this.$router.push({
                name: this.$store.state.inbound.purchaseOrder.baseRouterName,
            });
            this.$vs.loading.close();
        },
        handleChangeAttachment(event) {
            this.attachment = event.target.files ? event.target.files[0] : null;
        },
        initData() {
            this.$vs.loading();
            this.$http
                .get(`${this.$store.state.inbound.purchaseOrder.baseUrlPath}/receive/edit-dto/${this.id}`)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.form.id = resp.data.id;
                        this.form.purchase_order_id = resp.data.purchase_order_id;
                        this.form.warehouse = resp.data.warehouse;
                        this.form.supplier = {
                            code: resp.data.supplier_code,
                            name: resp.data.supplier_name,
                        };
                        this.form.dn_date = resp.data.dn_date;
                        this.form.code = resp.data.code;
                        this.form.notes = resp.data.notes;

                        if (resp.data.status !== "") {
                            this.form.status = resp.data.status;
                        }
                        this.form.purchase_date = resp.data.purchase_date;
                        this.form.date = resp.data.date;
                        this.form.delivery_order_number = resp.data.delivery_order_number;
                        this.form.shipment_number = resp.data.shipment_number;
                        var mapLine = new Map()
                        if (resp.data.line_dto) {
                            resp.data.line_dto.forEach((item) => {
                                if (item.is_damaged == 5) {
                                    item.hu_line = [];
                                    mapLine.set(item.purchase_order_line_id, item);
                                }
                            });

                            resp.data.line_dto.forEach((item) => {
                                if (item.is_damaged == 3) {
                                    const line = mapLine.get(item.purchase_order_line_id);

                                    item.item_unit = {
                                        id: item.item_unit_id,
                                        name: item.hu_name,
                                        amount_uom: item.amount_uom
                                    }

                                    if (line.hu_line) {
                                        line.hu_line.push(item);
                                    } else {
                                        line.hu_line = [item];
                                    }
                                    mapLine.set(item.purchase_order_line_id, line);
                                }
                            });
                            mapLine.forEach((item, key) => {
                                if (item.hu_line.length < 1) {
                                    const parent = Object.assign({}, item);
                                    delete item.hu_line;
                                    item.quantity = 0;
                                    item.hu_name = null;
                                    item.is_damaged = 3;
                                    parent.hu_line = [item];
                                    mapLine.set(key, parent);
                                }
                            });
                            var prLine = []
                            mapLine.forEach((item, key) => {
                                prLine.push(item)
                                item.key = key;
                            });
                        }
                        this.form.purchase_receive_lines = prLine;
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
    }
}
</script>


<style>
td.td.vs-table--td {
    vertical-align: text-top !important;
}
.vs-input--input:disabled, input:disabled {
    pointer-events: auto !important;
}
</style>